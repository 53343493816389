<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.users') }} :: {{ $t('usersManagement.edit') }}
        </h3>

        <router-link :to="{ name: 'manage-users' }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>
            {{$t("buttons.back")}}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">

          <b-input-group :prepend="$t('inputs.email')">
            <b-form-input
              v-model="model.email"
              :readonly="isEdit"
              maxlength="100"
              type="email"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.name')">
            <b-form-input
              v-model="model.name"
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.preferedLanguage')">
            <b-form-select
              required
              v-model="model.language"
              :options="languageOpts"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t(`inputs.${isEdit ? 'newPassword' : 'password'}`)">
            <b-form-input
              v-model="model.password"
              maxlength="25"
              pattern=".{6,25}"
              :title="$t('messages.invalidPasswordLength')"
              type="text"
              :required="!isEdit"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12" class="mb-3" v-if="isEdit">
          <b-input-group :prepend="$t('inputs.createdAt')">
            <b-form-input
              :value="formatDate(model.createdAt)"
              type="text"
              readonly
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-form-checkbox
        v-model="model.isActive"
        :value="true"
        :unchecked-value="false"
        class="mt-2"
      >
        {{ $t('inputs.active') }}
      </b-form-checkbox>

      <hr />

      <b-row class="mt-4">
        <b-col cols="12" class="mb-3">
          <h5>{{ this.$t('usersManagement.titlePermissions') }}</h5>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="3"
          sm="12"
          v-for="item in Object.keys(permissions)" :key="item"
        >
          <div class="bg-white border rounded mb-3 p-3">
            <h6
              class="text-primary text-decoration-underline pl-4 text-uppercase"
            >
              {{ permissions[item][languageKey] }}
            </h6>

            <b-form-checkbox
              v-for="section in permissions[item].items"
              :key="section.id"
              v-model="model.permissions"
              :value="section.id"
              :unchecked-value="null"
            >
              {{ section[languageKey] }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="text-right">
          <template v-if="isEdit && !model.isMaster">
            <b-button
              :disabled="loading"
              variant="danger"
              @click="deleteModal = true"
            >
              {{ $t('buttons.delete') }}
            </b-button>

            <span class="mx-2" />
          </template>

          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">
          {{ $t('messages.loading' ) }}...
        </h5>
      </b-col>
    </b-row>

    <b-modal
      centered
      v-model="deleteModal"
      @ok="onDeleteUser"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">{{ $t('messages.deleteConfirmMessage') }}</div>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import UsersPermissions from '../../../../services/UsersPermissions';
import UsersManage from '../../../../services/UsersManage';
import { adaptPermissions } from '../../../../assets/js/helpers';
import Toast from '../../../../assets/js/toast';

export default {
  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      deleteModal: false,

      languageOpts: [
        { text: 'English', value: 'en' },
        { text: 'Português', value: 'pt' },
      ],

      permissions: {},
      arrPermissions: [],

      model: {
        name: '',
        email: '',
        password: '',
        isActive: true,
        language: 'en',
        permissions: [],
      },
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      this.loading = true;

      await this.onLoadPermissions();
      await this.onLoadUser();

      this.loading = false;
      this.loaded = true;
    },

    async onLoadPermissions() {
      try {
        const perms = await UsersPermissions.get();
        this.arrPermissions = perms;
        this.permissions = adaptPermissions(perms);
      } catch (e) {
        Sentry.captureException(e);

        Toast(this, e.message);
      }
    },

    async onLoadUser() {
      try {
        const { id } = this.$route.params;

        if (id) {
          this.isEdit = true;

          const user = await UsersManage.getById(id);

          this.model = {
            ...user,
            password: '',
            permissions: user.permissions.map((item) => item.id),
          };
        }
      } catch (e) {
        Sentry.captureException(e);

        Toast.error(this, e);
        console.log('Falha ao carregar!', e);
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        const hiddens = this.arrPermissions.filter((p) => p.hide).map((h) => h.id);

        const virtualTablePerms = this.arrPermissions.filter((p) => p.sectionEN === 'Virtual Tables' && !p.hide).map((item) => item.id);
        const hasVirtual = this.model.permissions.find((item) => virtualTablePerms.includes(item));

        if (hasVirtual) {
          this.model.permissions = [
            ...new Set([...this.model.permissions, ...hiddens]),
          ];
        } else {
          this.model.permissions = this.model.permissions.filter((item) => !hiddens.includes(item));
        }

        await UsersManage[action](this.model, this.model.id);

        Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'manage-users' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },

    async onDeleteUser() {
      try {
        this.loading = true;

        await UsersManage.delete(this.model.id);

        Toast.success(this, 'messages.removeSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'manage-users' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },

    formatDate(dt) {
      try {
        return DateTime
          .fromISO(dt)
          .setLocale(this.$i18n.locale)
          .toFormat('ff');
      } catch (e) {
        return dt;
      }
    },
  },

  computed: {
    languageKey() {
      const obj = {
        en: 'nameEN',
        pt: 'namePT',
        default: 'nameEN',
      };

      return obj[this.$i18n.locale] || obj.default;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
